import React, { useRef, useState, useCallback } from "react";
import PropTypes, { element } from "prop-types";
import { Form, Input, Button, Row, Checkbox, Col, Typography } from "antd";
import Cookies from "js-cookie";
import axios from "axios";
import { IdcardOutlined } from "@ant-design/icons";
import Heading from "../../components-v2/Base/HeadingBuilder";
import bgImage from "../../images/hero-images/guy-form-bg.png";
import chevron from "../../images/svg/chevron_left.svg";
import check from "../../images/svg/checkmark.svg";
import GuyFormStyles from "./GuyFormStyles";

const { Title } = Typography;

const GuyForm = (props) => {
  const { leadType, formId, thankYouPath, elementId } = props;
  const form = useRef(null);
  const [loading, setLoading] = useState(false);
  const [textMessages, setTextMessages] = useState(false);
  const emailValidation = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  const phoneValidation = new RegExp(
    /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im
  );

  const onTextMessagesToggle = useCallback(() =>
    setTextMessages(() => !textMessages)
  );

  const blockList = [];

  const onFinish = (values) => {
    setLoading(true);
    const formData = {
      fields: [
        {
          name: "firstname",
          value: values.name,
        },
        {
          name: "email",
          value: values.email,
        },
        {
          name: "phone",
          value: values.phone,
        },
        {
          name: "company",
          value: values.company,
        },
        // {
        //   name: 'city',
        //   value: values.location,
        // },
        {
          name: "text_messages",
          value: textMessages,
        },
        {
          name: "gclid",
          value: Cookies.get("gclid") || "",
        },
        {
          name: "utm_source",
          value: Cookies.get("utmSource") || "organic",
        },
        {
          name: "utm_medium",
          value: Cookies.get("utmMedium") || "",
        },
        {
          name: "utm_campaign",
          value: Cookies.get("utmCampaign") || "",
        },
        {
          name: "utm_content",
          value: Cookies.get("utmContent") || "",
        },
        {
          name: "utm_term",
          value: Cookies.get("utmTerm") || "",
        },
      ],
      context: {
        hutk: Cookies.get("hubspotutk") || undefined,
        pageUri: window.location.href,
        pageName: document.title,
      },
    };
    axios({
      method: "post",
      url: `https://api.hsforms.com/submissions/v3/integration/submit/2970821/${formId}`,
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify(formData),
    })
      .then(() => {
        if (window.sessionStorage.getItem("Name")) {
          window.sessionStorage.removeItem("Name", values.name);
          window.sessionStorage.setItem("Name", values.name);
        } else {
          window.sessionStorage.setItem("Name", values.name);
        }
        if (typeof window !== `undefined` && typeof dataLayer !== `undefined`) {
          // eslint-disable-next-line no-undef
          dataLayer.push({ event: `${leadType}-lead` });
        }
      })
      .then(() => {
        window.location.pathname = thankYouPath;
      })
      // eslint-disable-next-line consistent-return
      .catch((error) => {
        if (error.response) {
          if (
            error.response.data.errors[0].errorType.toString() ===
            "INVALID_EMAIL"
          ) {
            // eslint-disable-next-line no-alert
            return alert(
              `Looks like the email ${values.email} can not be validated.
                Please use a valid email.`
            );
          }
        } else if (error.request) {
          // eslint-disable-next-line no-console
          console.log(error.request);
        } else {
          // eslint-disable-next-line no-console
          console.log("Error", error.message);
        }
      });
  };
  return (
    <section className="guy-form" ref={form}>
      <form
        name="Lead Demo Form"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        hidden
      >
        <input type="text" name="name" />
        <input type="text" name="email" />
        <input type="text" name="company" />
        <input type="text" name="phone" />
        <input type="text" name="textMessages" />
      </form>
      <Row className="header-form-wrapper">
        <Col xs={24}>
          <Heading
            level={2}
            injectionType={2}
            withBlueSymbol
            symbol="."
            className="text-center"
          >
            Book your consultation
          </Heading>
        </Col>
        {/* <Col xs={24}>
          <p style={{ fontSize: 20, color: '#748093' }} className="text-center">
            Adapt and grow your restaurant with SpotOn Restaurant point-of-sale,
            the cutting-edge solution showcased on Guy’s Restaurant Reboot.
          </p>
        </Col> */}
      </Row>
      <Row justify="center" align="middle">
        {blockList.map((item, i) => (
          <Col lg={8} xs={24}>
            <div
              key={`${i.toString()}__list`}
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "24px",
              }}
            >
              <img src={check} style={{ marginRight: "8px" }} alt="" />
              <p
                style={{
                  lineHeight: "1.2",
                  fontWeight: "800",
                  fontSize: "16px",
                  marginBottom: "0",
                }}
              >
                {item}
              </p>
            </div>
          </Col>
        ))}
      </Row>
      <div style={{ background: `url(${bgImage})` }} className="guy-form-cont">
        <Form
          name="get-a-demo"
          className="lead-form in-lp"
          layout="vertical"
          initialValues={{
            remember: false,
          }}
          onFinish={onFinish}
        >
          <Heading level={3} injectionType={3}>
            Learn more
          </Heading>
          {/* Name */}
          <input type="hidden" name="Learn more" value="contact" />
          <Row>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please enter your name",
                    min: 2,
                  },
                ]}
              >
                <Input size="large" placeholder="e.g. John Doe" />
              </Form.Item>
            </Col>
            {/* Email */}
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter a valid email",
                    pattern: emailValidation,
                  },
                ]}
              >
                <Input size="large" placeholder="e.g. john@mybusiness.com" />
              </Form.Item>
            </Col>
          </Row>
          {/* Business Name */}
          <Row>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                label="Business name"
                name="company"
                rules={[
                  {
                    required: true,
                    message: "Please enter your business name",
                    min: 3,
                  },
                ]}
              >
                <Input size="large" placeholder="e.g. MyBusiness Inc. " />
              </Form.Item>
            </Col>
            {/* Phone */}
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                label="Phone number"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please enter your phone",
                    pattern: phoneValidation,
                  },
                ]}
              >
                <Input size="large" placeholder="e.g. (555) 555-1243 " />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Form.Item name="textMessages">
                <Checkbox
                  checked={textMessages}
                  onChange={onTextMessagesToggle}
                >
                  I agree to receive text message updates
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          {/* Submit */}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="cta-primary"
              style={{ marginTop: 20 }}
              loading={loading}
              size="small"
            >
              Submit
              <img
                src={chevron}
                alt="chevron icon"
                style={{ margin: "5px 5px 2px 10px" }}
              />
            </Button>
          </Form.Item>
        </Form>
      </div>

      <GuyFormStyles />
    </section>
  );
};

GuyForm.propTypes = {
  formId: PropTypes.string.isRequired,
  leadType: PropTypes.string.isRequired,
  thankYouPath: PropTypes.string,
};
GuyForm.defaultProps = {
  thankYouPath: "/thank-you",
};

export default GuyForm;
